import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useEffect } from 'react';
import AppContext from '~contexts/AppContext';
import { GET_PAYMENT_STATUS } from '~graphql/queries';
import getSearchParams from '~utils/getSearchParam';
// import { PAYMENT_INFO_KEY } from '~utils/localstorageHelper';
import { Routes } from '../../types/routes';
import { dynamicNavigate } from '../../utils/routeHelper';
// import { setLocalStorageValue } from '../../utils/localstorageHelper';
// import { localstorageKeys } from '../../types/localstorageTypes';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const authentication = () => {
  const {
    actions: { resetItems },
  } = useContext(AppContext);
  const styles = useStyles();
  const orderId = getSearchParams('orderId');

  const { loading, error, data: paymentStatus } = useQuery(
    GET_PAYMENT_STATUS,
    {
      variables: { orderId: Number(orderId) },
    }
  );

  useEffect(() => {
    if (!loading && error) {
      handlePaymentFailure();
    } else if (!loading && !error) {
      const {
        orderGetPaymentStatus: { paymentConfirmation },
      } = paymentStatus;
      if (paymentConfirmation === 'Completed') {
        handlePaymentComplete();
      } else {
        handlePaymentFailure();
      }
    }
  }, [paymentStatus, error]);

  const handlePaymentComplete = () => {
    resetItems();
    dynamicNavigate(
      `${Routes.ORDERSTATUS}?orderId=${orderId}`
    );
  };

  const handlePaymentFailure = () => {
    dynamicNavigate(`${Routes.PAYMENT_FAILED}`);
  };

  return (
    <div className={styles.container}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default authentication;
